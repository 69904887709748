import React, {StrictMode, Suspense } from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import User from "layouts/User.js";
import Auth from "layouts/Auth.js";
import None from "layouts/None.js";
import store from "./store.js";
import "bootstrap/dist/css/bootstrap.min.css?v=2.1";
import "react-selectize/themes/index.css";
import "react-select-plus/dist/react-select-plus.css";
import "assets/css/xcellence.css?v=2.1";
import "assets/css/custom.css?v=2.1";
import "assets/css/cookie-yes.css";
import "assets/css/retina.css?v=2.1";
import "assets/css/tablet.css?v=2.1";
import "assets/css/mobile.css?v=2.1";
import "assets/css/grafico.css?v=2.1";
import "assets/css/index.css";
import "antd/dist/antd.css";
import "assets/css/loadingBar.css?v=2.1";
import { Provider } from "react-redux";
import { PrivateRoute } from "helpers/PrivateRoute";
import { history } from "helpers/history";
import { FixedSackbar } from "components/FixedSnackbar.js";
import { ThemeProvider, createTheme } from "@material-ui/core";
import moment from "moment";
import MainComponent from "components/MainComponent.js";
import { BestPracticeView } from "views/start/Resources/BestPracticeView";

const Loader = () => {
  return <div>Cargando...</div>;
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#2b74c4',
    },
    secondary: {
      main: '#fff',
      contrastText: '#2b74c4',
    }
  },
  overrides: {
    MuiButton: {
      label: {
        textTransform: 'none'
      },
      contained: {
        borderRadius: 18,
      },
      containedPrimary: {
        padding: '10px 20px',
      },
      containedSecondary: {
        padding: '8px 20px',
        border: '2px solid #2b74c4',
        '&:hover': {
          backgroundColor: '#eee',
        }
      }
    },
  },
  typography: {
    fontFamily: [
      'MuseoSans',
      'sans-serif',
    ],
    h5: {
      textTransform: 'uppercase'
    }
  }
});

const lastPeriod = moment().month() >= 6
  ? moment().subtract(1, 'year')
  : moment().subtract(2, 'year');

const lang = store.getState().setting.locale.toLowerCase();

const langReg = '/:lang(es|ca)?';

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<Loader />}>
          <Router history={history}>
            <MainComponent>
              <Switch>
                <Route path={`${langReg}/buenaspracticas`} component={BestPracticeView} />
                <Route path={`${langReg}/user`} component={User} />
                <Route path={`${langReg}/none`} component={None} />
                <PrivateRoute path={`${langReg}/auth`} component={Auth} />
                <Redirect from={`${langReg}`} to={`/${lang}/auth/start`} />
              </Switch>
            </MainComponent>
          </Router>
          <FixedSackbar />
        </Suspense>
      </ThemeProvider>
    </Provider>
  </StrictMode>,
  document.getElementById("root")
);
