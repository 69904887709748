import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import {Language} from 'components/Language';
import { Grid } from "@material-ui/core";

const useStyles = makeStyles({
  footerContainer: {
    backgroundColor: 'var(--footer-color)',
    padding: 15,
    '& a:hover': {
      text: '#fff',
      textDecoration: 'underline',
    },
    '& a, & span': {
      color: '#fff',
    }
  }
});

function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footerContainer}>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid
          container
          item
          xs={12}
          md={6}
          spacing={2}
          alignItems="center"
        >
          <Grid item>
            <a href="https://www.fundacionbertelsmann.org/">
              <Language langKey="bertelsmann_foundation" />
            </a>
          </Grid>
          <Grid item> <span>|</span> </Grid>
          <Grid item>
            <a href="https://www.xcelence.es/user/contact">
              <Language langKey="contact" />
            </a>
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="column"
          style={{  width: 'fit-content' }}
        >
          <Grid item>
            <a href="https://xcelence.es/politica-privacidad/">
              <Language langKey="privicy_policies" />
            </a>
          </Grid>
          <Grid item>
            <a href="https://xcelence.es/aviso-legal/">
              <Language langKey="term_conditions" />
            </a>
          </Grid>
          <Grid item>
            <a href="https://xcelence.es/politica-de-cookies/">
              <Language langKey="policy_cookies" />
            </a>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
}

export default (Footer);
